<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-05 17:00:18
-->
<template>
  <el-dialog :visible.sync="adjustOrderShow" :close-on-click-modal="false" :before-close="cancel" title="调整顺序" width="600px" top="4%">
    <div class="main-content">
        <!-- 拖拽外层列表区域 -->
        <div class="adjust-content">
          <draggable v-model="dragGroupList" @remove="removeHandleGroup" :options="dragOptionsGroup">
              <!-- 列表过渡  tag：指定transition-group 渲染为指定的元素 -->
            <transition-group tag="div" :id="`group`" class="item-ul">
                <!-- 拖拽区域 -->
                <div class="group" v-for="(questionItem,questionIndex) in dragGroupList" :key="questionItem.groupIndex">
                    <!-- 分组名称 -->
                    <div class="group-name" v-if="questionItem.groupName">
                      <com-title :title="questionItem.groupName" />
                      <img class="img_style" src="@/assets/img/icon_drag.png" alt="">
                    </div>
                    <!-- 拖拽容器 v-model 绑定 分组下的数组。remove 从列表中移除元素时触发。 :options：通过绑定 options 来设置配置项-->
                    <draggable v-model="questionItem.questionInfo" @remove.stop="removeHandleTopic" :options="dragOptionsTopic">
                        <!-- 列表过渡  tag：指定transition-group 渲染为指定的元素 -->
                        <transition-group tag="div" :id="`topic${questionIndex}`" class="item-ul">
                            <!-- 循环 分组下的试题列表，渲染 试题 列表的内容 -->
                            <div v-for="(item,index) in questionItem.questionInfo" :key="item.index" class="group-content">
                                <span>{{index+1}}.{{item.title}}</span>
                                <span style="display:inline-block;"><img src="@/assets/img/icon_drag.png" alt="" class="img_style"/></span>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </transition-group>
          </draggable>
            <!--  -->
        </div>
    </div>
    <!-- 题目列表表格-结束-->
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submitSelectTopic()">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import comTitle from '@/components/comTitle'
import draggable from 'vuedraggable'
export default {
  components: { comTitle, draggable },
  props: {
    adjustOrderShow: {
      type: Boolean,
      default: false
    },
    questionsGroupList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dragGroupList: [],
      dragOptionsGroup: {
        animation: 120, // 动画时间 单位：ms
        scroll: true, // 当排序的容器是个可滚动的区域，拖放可以引起区域滚动
        group: 'sortgrouplist', // 分组用的，同一组的不同list可以相互拖动
        // selector 格式为简单css选择器的字符串，当拖动列表单元时会生成一个副本作为影子单元来模拟被拖动单元排序的情况，
        ghostClass: 'ghost-group-style'// 此配置项就是来给这个影子单元添加一个class，我们可以通过这种方式来给影子元素进行编辑样式
      },
      dragOptionsTopic: {
        animation: 120,
        scroll: true,
        group: 'sorttopiclist',
        ghostClass: 'ghost-topic-style'
      }
    }
  },
  created () {
    this.dragGroupList = JSON.parse(JSON.stringify(this.questionsGroupList))
  },
  methods: {
    // 取消
    cancel () {
      this.$parent.adjustOrderShow = false
    },
    _submitSelectTopic () {
      for (let i = 0; i < this.dragGroupList.length; i++) {
        this.dragGroupList[i].groupIndex = i
        if (this.dragGroupList[i].questionInfo && this.dragGroupList[i].questionInfo.length > 0) {
          this.dragGroupList[i].questionInfo = this.dragGroupList[i].questionInfo.map((item) => {
            return {
              ...item,
              groupIndex: this.dragGroupList[i].groupIndex
            }
          })
        }
      }
      console.log(this.dragGroupList, 'dragGroupList')
      this.$emit('adjustTopicOrder', this.dragGroupList)
      this.$parent.adjustOrderShow = false
    },
    removeHandleGroup (event) {
      console.log(event)
      // this.$message.success(`从 ${event.from.id} 移动到 ${event.to.id} `)
      // console.log(this.dragGroupList, 'dragGroupList11')
    },
    removeHandleTopic (event) {
      console.log(event)
      // this.$message.success(`从 ${event.from.id} 移动到 ${event.to.id} `)
      // console.log(this.dragGroupList, 'dragGroupList22')
    }
  }
}
</script>
<style lang="scss" scoped>
.adjust-content{
  margin-top: 15px;
  .group{
    .group-name{
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .group-content{
      color: rgba($color: #000000, $alpha: 0.65);
      font-size: 16px;
      margin:20px 0px 26px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
/deep/.el-dialog__body {
  padding: px 24px;
  min-height: 200px;
  max-height: 450px;
  overflow: auto;
}
.img_style{
  width:24px;
  height:24px;
}
</style>
