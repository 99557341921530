<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-13 11:16:32
-->
<template>
  <el-drawer
    ref="drawer"
    title="从题库中选择"
    :visible.sync="drawer_"
    :direction="direction"
    @close="cancel"
    :wrapperClosable="false"
    :show-close="false"
    :size="drawerSize"
  >
  <div class="demo-drawer__content">
    <el-button
      :style="{ right: drawerSize }"
      class="add-drawer-close-btn"
      type="primary"
      @click="cancel">
      <span class="iconfont icon-icon_close"></span>
    </el-button>
    <div class="main-content">
      <!-- 左侧--开始 -->
      <div class="left-box">
        <!-- 筛选表单--查询对应题目---开始 -->
        <el-form ref="searchForm" inline :model="searchForm" size="small" class="left-form">
          <el-form-item label="所属分组">
            <GlobalTreeSelect
              style="width:200px;"
              v-model="searchForm.groupId"
              nodeKey="id"
              filterable
              :treeProps="defaultProps"
              :data="belongGroupTree"
              @input="_getBelongGroup"
            >
            </GlobalTreeSelect>
          </el-form-item>
          <el-form-item label="题库名称">
            <el-input v-model="searchForm.questionBankName" style="width:200px;" @input="_topicBankInput" @blur="_topicBankBlur" placeholder="请输入题库名称"></el-input>
          </el-form-item>
          <!-- <el-form-item label="所属题库">
            <el-select v-model="searchForm.questionBankId" @change="_selectTopicBank" filterable>
              <el-option
                v-for="item in topicBankList"
                :key="item.questionBankId"
                :value="item.questionBankId"
                :label="item.questionBankName"
              ></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      <!-- 筛选表单--查询对应题目---结束 -->
      <!-- 题库名称 -->
      <div class="left-content">
        <el-checkbox-group v-model="checkedTopicBank" @change="handleCheckedTopicBankChange">
          <el-checkbox v-for="item in topicBankListArr" :label="item.questionBankId" :key="item.questionBankId">{{item.questionBankName}}</el-checkbox>
        </el-checkbox-group>
      </div>
      </div>
      <!-- 左侧--结束 -->
      <!-- 右侧--开始 -->
      <div class="right-box">
        <div>
          <el-form ref="topicForm" inline :model="topicForm" size="small" class="left-form">
            <el-form-item label="题目名称">
              <el-input v-model="topicForm.title" style="width:200px;" placeholder="请输入题目名称"></el-input>
            </el-form-item>
            <el-form-item label="题目类型">
            <el-select v-model="topicForm.questionType" filterable clearable>
              <el-option v-for="item in questionTypeList" :key="item.key" :value="item.key" :label="item.value"></el-option>
            </el-select>
          </el-form-item>
            <el-button @click="_resetTopicList" style="margin-left:16px;">重置</el-button>
            <el-button type="primary" @click="_queryTopicList" style="margin-left:16px;">查询</el-button>
          </el-form>

        </div>
        <!-- 题目列表表格--开始-->
        <div class="index-list-table mt16">
          <el-table :data="tableData" height="650px" @selection-change="_selectTableData" :row-key="_getRowKeys">
            <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            <el-table-column prop="title" label="题目名称" width="400px">
              <template slot-scope="scope">{{scope.row.title||'—'}}</template>
            </el-table-column>
            <el-table-column prop="questionType" label="题目类型" width="200px">
              <template slot-scope="scope">{{_getQuestionTypeName(scope.row.questionType)}}</template>
            </el-table-column>
            <el-table-column prop="score" label="分数" width="200px">
              <template slot-scope="scope">{{scope.row.score||'—'}}</template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal">
          </el-pagination>
        </div>
        <!-- 题目列表表格-结束-->

      </div>
      <!-- 右侧--结束 -->

    </div>
    <div class="footer-btn">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submitSelectTopic()">确定</el-button>
    </div>
  </div>
  </el-drawer>
</template>
<script>
import { topicManagePageListApi, questionBankGroupTreeListApi, questBankManagePageListApi } from '@/api/examApi'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'

export default {
  components: { GlobalTreeSelect },
  props: {
    selectTopicShow: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'rtl'
    },
    drawerSize: {
      type: String,
      default: '50%'
    }
  },
  data () {
    return {
      defaultProps: {
        label: 'groupName',
        children: 'children'
      },
      belongGroupTree: [], // 所属分组
      topicBankList: [], // 题库列表
      tableData: [], // 题目列表
      tableTotal: 0, // 题目总数
      searchForm: {
        groupId: '', // 所属分组
        questionBankId: '',
        questionBankName: '', // 题库名称
        page: 1,
        pageSize: 10
      },
      topicForm: {
        title: '', // 题目名称
        questionType: ''// 题目类型
      },
      selectedQuestionKeys: [],
      checkedTopicBank: [],
      topicBankListArr: [],
      isIndeterminate: true,
      questionTypeList: [
        { key: 'SINGLE_CHOICE_TYPE', value: '单项选择' },
        { key: 'MULTIPLE_CHOICE_TYPE', value: '多项选择' },
        { key: 'JUDGMENT_TYPE', value: '判断题' },
        { key: 'GAP_FILLING_TYPE', value: '填空题' },
        { key: 'ESSAY_QUESTION_TYPE', value: '问答题' }
      ]
    }
  },
  // 计算属性
  computed: {
    drawer_: {
      get () {
        return this.selectTopicShow// 值一改变就会调用set【可以用set方法去改变父组件的值】
      },
      set (v) {
        //   this.$emit('changeDrawer', v)
      }
    }
  },
  watch: {
    // 题库选择--监听
    'searchForm.questionBankId' (newVal, oldVal) {
      if (!newVal) {
        this.tableData = []
        this.tableTotal = 0
      }
    },
    topicBankListArr (newVal, oldVal) {
      if (!newVal.length) {
        this.checkedTopicBank = []
        this.tableData = []
        this.tableTotal = 0
      }
    },
    'searchForm.groupId' (newValue, oldValue) {
      if (!newValue) {
        this.searchForm.questionBankName = ''
        this.topicBankListArr = []
      }
    }

  },
  created () {
    this._getQuestionBankTree()// 获取题库分组列表
  },
  methods: {
    // 获取题库分组--列表
    _getQuestionBankTree () {
      questionBankGroupTreeListApi().then((res) => {
        if (res.code === 200) {
          this.belongGroupTree = res.data
        }
      })
    },
    // 选择所属分组--获取题库列表
    _getBelongGroup (value) {
      this.searchForm.questionBankId = null
      if (value) {
        this.searchForm.groupId = value
        this._getTopicList(value, this.searchForm.questionBankName)
      }
    },
    // 题库列表
    _getTopicList (questionBaseId, topicBankName) {
      const params = {
        questionBankGroupId: questionBaseId,
        questionBankName: topicBankName,
        page: 1,
        pageSize: 9999
      }
      questBankManagePageListApi({ ...params }).then((res) => {
        if (res.code === 200) {
          this.topicBankList = res.object.results
          this.topicBankListArr = JSON.parse(JSON.stringify(this.topicBankList))
          console.log(this.topicBankListArr, 'this.topicBankListArr9999')
        }
      })
    },
    // 输入题库名称
    _topicBankInput () {
      if (this.searchForm.groupId) {
        this._getTopicList(this.searchForm.groupId, this.searchForm.questionBankName)
      }
    },
    _topicBankBlur () {
      if (!this.searchForm.groupId) {
        this.$message.error('请先选择所属分组！')
      }
    },
    // 查询题目
    _queryTopicList () {
      if (this.searchForm.questionBankId && this.searchForm.groupId) {
        this._getQuestionTitleList()
      }
    },
    // 重置题目
    _resetTopicList () {
      this.topicForm = {
        title: '',
        questionType: ''
      }
      if (this.searchForm.questionBankId && this.searchForm.groupId) {
        this._getQuestionTitleList()
      }
    },
    // 选择题库---获取题目列表
    _selectTopicBank (e) {
      if (e) {
        this._getQuestionTitleList()
      }
    },
    // 题目列表
    _getQuestionTitleList () {
      topicManagePageListApi({ ...this.searchForm, ...this.topicForm }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.tableTotal = res.object.total
        }
      })
    },
    // 选择题目
    _selectTableData (val) {
      console.log(val, 'val22')
      this.selectedQuestionKeys = val
    },
    _getRowKeys (row) {
      return row.id
    },
    // 题目列表--分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.searchForm.page = 1
      this._getQuestionTitleList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getQuestionTitleList()
    },
    // 获取题目类型
    _getQuestionTypeName (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          value = '—'
          break
      }
      return value
    },
    // 取消
    cancel () {
      this.$parent.selectTopicShow = false
    },
    _submitSelectTopic () {
      if (!this.selectedQuestionKeys.length) {
        return this.$message.error('请先选择！')
      }
      for (let i = 0; i < this.selectedQuestionKeys.length; i++) {
        this.selectedQuestionKeys[i].groupIndex = i
        if (this.selectedQuestionKeys[i].questionInfo && this.selectedQuestionKeys[i].questionInfo.length > 0) {
          this.selectedQuestionKeys[i].questionInfo = this.selectedQuestionKeys[i].questionInfo.map((item) => {
            return {
              ...item,
              groupIndex: this.selectedQuestionKeys[i].groupIndex
            }
          })
        }
      }
      this.$emit('selectQuestionData', this.selectedQuestionKeys)
      this.$parent.selectTopicShow = false
    },
    handleCheckedTopicBankChange (value) {
      this.checkedTopicBank = [value[value.length - 1]]
      if (this.checkedTopicBank.length) {
        this.searchForm.questionBankId = this.checkedTopicBank[0]
        if (this.checkedTopicBank[0]) {
          this._getQuestionTitleList()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px;
  text-align: center;
  padding: 8px 9px;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .add-group-btn {
    width: 102px;

    border: 1px solid #0089ff;
    font-size: 14px;
    color: #0089ff;
  }
}
.add-group-name {
  margin-bottom: 10px;
  width: 298px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    flex: 1;
  }
}
/deep/.el-dialog__body {
  min-height: 200px;
  max-height: 600px;
  overflow: auto;
}
.demo-drawer__content{
  display: flex;
  flex-direction: column;
  height: 100%;
}
/deep/.el-drawer__header{
  border-bottom: 1px solid #DCDCDC;
  padding: 18px 24px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0,0,0,0.9);
}
.add-drawer-close-btn {
  padding: 0px!important;
  position: fixed;
  top: 0%;
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 3px;
}
.change-ask-close-btn {
  padding: 0px;
  position: fixed;
  top: 0%;
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 0px;
}
//
.main-content{
  display: flex;
  flex-direction: row;
  .left-box{
    flex: 1.5;
    border-right: 1px solid #DCDCDC;
    .left-form{
      padding: 24px 0px 0px 24px;
    }
    .left-content{
      padding: 16px 0px 0px 24px;
      border-top: 1px solid #DCDCDC;
      .el-checkbox-group{
        display: flex;
        flex-direction: column;
      }
      .el-checkbox{
        margin: 4px 0px;
      }
    }
  }
  .right-box{
    padding: 24px;
    flex: 3.5;
  }
}
.footer-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0;
  box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.04);
}
</style>
