<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-04 14:37:59
-->
<template>
  <el-dialog :visible.sync="scoreAdjustShow" :close-on-click-modal="false" :before-close="cancel" title="分值调整" width="440px" top="4%">
  <!-- 题目类型--结束 -->
    <div>
      <div v-for="(item,index) in adjustData" :key="index" class="group-style">
          <div v-if="item.questionTypeValue!=='判断题'&&item.questionTypeValue!=='问答题'">
            <com-title :title="item.questionTypeValue" />
            <div class="group-style-score">
              <span>共{{item.questionInfo.length}}道题，每道</span>
              <el-input v-model="item.score" placeholder="100" class="score-input" @input="_scoreInput(item)"></el-input>
              <span>分，单项选择合计{{Number(item.questionInfo.length)*Number(item.score)}}分</span>
            </div>
          </div>
      </div>
    </div>
    <!-- 题目类型--结束 -->
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submitScoreAdjust()">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import comTitle from '@/components/comTitle'
export default {
  components: { comTitle },
  props: {
    scoreAdjustShow: {
      type: Boolean,
      default: false
    },
    questionsGroupList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      allTopicList: [], // 所有题目
      adjustData: [] // 需要调整的数据列表
    }
  },
  created () {
    if (this.questionsGroupList?.length !== 0) {
      this.questionsGroupList.forEach((groupInfo, groupIndex) => {
        this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
      })
    }
    const tempQuestionAllArr = JSON.parse(JSON.stringify(this.questionsGroupList))
    const tempAllTopicList = []
    tempQuestionAllArr.forEach((resOne) => {
      tempAllTopicList.push(...resOne.questionInfo)
    })
    this.allTopicList = JSON.parse(JSON.stringify(tempAllTopicList))
    this.allTopicList.forEach((itemAll, indexAll) => { // 添加每道题的序号
      itemAll.currentIndexSort = indexAll + 1
    })
    this.adjustData = this._handlerDatasByTopic(this.allTopicList)
    console.log(this.adjustData, 'this.adjustData9999')
  },
  methods: {
    // 试卷-- 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex) {
      groupInfo.groupIndex = groupIndex
      groupTopicList[groupIndex].groupTopicNumber = groupTopicList[groupIndex].questionInfo.length
      groupTopicList[groupIndex].groupTopicSumScore = this._getCountGroupTopicSumScore(groupTopicList[groupIndex].questionInfo)
      groupTopicList[groupIndex].score = groupTopicList[groupIndex].questionInfo.score// 每题分值
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          groupName: groupTopicList[groupIndex].groupName,
          groupSort: groupTopicList[groupIndex].groupSort,
          groupTopicNumber: groupTopicList[groupIndex].groupTopicNumber,
          groupTopicSumScore: groupTopicList[groupIndex].groupTopicSumScore,
          ...topicItem,
          index: topicIndex,
          groupIndex: groupIndex,
          mark: topicItem.testerMark,
          correctStatus: topicItem.correctStatus,
          show: false
        }
      })
    },
    // 计算每个分组的总分
    _getCountGroupTopicSumScore (questionInfo) {
      let tempScore = 0
      questionInfo.forEach((item) => {
        tempScore = tempScore + Number(item.score)
      })
      return tempScore
    },
    // 将数据对象根据某一相同key合并成新的数组
    _handlerDatasByTopic (arr) { // 通过题目类型进行分组
      const obj = {}
      arr.forEach((item, index) => {
        const { questionType } = item
        const { questionTypeValue } = item
        const { groupIndex } = item
        const { groupName } = item
        const { groupSort } = item
        const { groupTopicNumber } = item
        const { groupTopicSumScore } = item
        const { score } = item
        if (!obj[questionType]) {
          obj[questionType] = {
            questionType,
            questionTypeValue,
            groupIndex,
            groupName,
            groupSort,
            groupTopicNumber,
            groupTopicSumScore,
            score,
            questionInfo: []
          }
        }
        obj[questionType].questionInfo.push(item)
      })
      const data = Object.values(obj) // 最终输出
      return data
    },
    // 分值调整
    _scoreInput (item) {
      item.score = Number(item.score)
      item.groupTopicSumScore = Number(item.score) * Number(item.groupTopicNumber)
      item.questionInfo.forEach((topicItem) => {
        topicItem.score = Number(item.score)
      })
    },
    // 取消
    cancel () {
      this.$parent.scoreAdjustShow = false
    },
    _submitScoreAdjust () {
      const finalAdjustDataOne = this.adjustData.map((item) => {
        return {
          questionType: item.questionType,
          questionTypeValue: item.questionTypeValue,
          score: item.score
        }
      })
      this.$emit('scoreAdjust', finalAdjustDataOne)
      this.$parent.scoreAdjustShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.group-style{
  margin:16px 0px;

}
.group-style-score{
  margin: 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.9);
  .score-input{
    width: 80px;
    margin: 0px 4px;
  }
}
/deep/.el-dialog__body {
  padding: 16px 20px;
}
</style>
