<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-15 15:13:19
-->
<template>
  <el-dialog :visible.sync="previewExamShow" :close-on-click-modal="false" :before-close="cancel" title="试卷预览" width="700px" height="550px" top="4%">
  <!-- 电脑、手机视图-开始 -->
  <el-radio-group v-model="viewType" class="view-type-style">
    <el-radio-button label="computerView" class="computer-view">电脑视图</el-radio-button>
    <el-radio-button label="phoneView"  class="phone-view">手机视图</el-radio-button>
  </el-radio-group>
  <!-- 电脑、手机视图-结束 -->
  <!-- 电脑视图-内容-开始 -->
  <div v-if="viewType=='computerView'" style="height:550px;overflow-y: scroll;">
    <!-- 主要内容-题目--开始 -->
    <div class="paper-topic-content" v-if="questionsGroupList.length">
      <div v-for="(questionsGroup,index) in questionsGroupList" :key="index">
        <div v-if="questionsGroup.groupName">
          <div style="display:flex; justify-content:space-between;">
              <com-title :title="questionsGroup.groupName" />
          </div>
        </div>
        <div class="containerBlock">
          <div v-for="(item, index) in questionsGroup?.questionInfo||[]" :key="index" class="topic">
            <div style="padding:16px;">
              <div class="title">
                {{ index + 1 }}.<span class="type">「{{ getTypeValue(item.questionType) }}」</span
                >{{ item.title }}<span class="grade">（{{ item.score }}分）</span>
              </div>
              <div class="explain">题目说明：{{ item.content }}</div>
              <!-- 题目说明---上传图片 开始-->
              <div v-if="item?.contentUrl!==''&&item?.contentUrl.length!==0&&item?.contentUrl[0]!==''">
                <el-image v-for="(item,index) in item?.contentUrl" :key="index" :src="item" style="margin-right:10px;width:100px;height:100px;" :preview-src-list="[item]"></el-image>
              </div>
              <!-- 题目说明---上传图片--结束 -->
              <!-- 单选题&&判断题 start -->
              <el-radio-group v-model="item.answer" v-if="item.questionType === 'SINGLE_CHOICE_TYPE' || item.questionType ==='JUDGMENT_TYPE'" class="prohibit-click">
                <el-radio
                  v-for="(ele, index) in item.options"
                  :key="index"
                  :label="`选项${index + 1}`"
                  >{{ ele.optionName }}</el-radio>
              </el-radio-group>
              <!-- 单选题&&判断题 end -->
              <!-- 多选题 start -->
              <el-checkbox-group
                class="prohibit-click"
                v-model="item.answerCheck"
                v-if="item.questionType == 'MULTIPLE_CHOICE_TYPE'"
              >
                <el-checkbox
                  v-for="(ele, index) in item.options"
                  :key="index"
                  :label="`选项${index + 1}`"
                  >{{ ele.optionName }}</el-checkbox>
              </el-checkbox-group>
              <!-- 多选题 end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <span style="margin: 20px;" v-else>暂无题目，请添加！</span>
    <!-- 主要内容-题目--结束 -->
  </div>
  <!-- 电脑视图-内容-结束 -->
  <!-- 手机视图-内容-开始 -->
  <div v-if="viewType=='phoneView'" style="height:550px;" class="phone-view-content">
    <phonePreviewPaper :paperId="paperId" :questionsGroupList="questionsGroupList"/>
    <!-- <div class="img-style">
      <img src="@/assets/img/mask_group.png"/>
    </div>
    <div class="text-style">
      <span> 程序猿正在流泪吐血敲代码中...</span>
      <span>敬请期待~</span>
    </div> -->
  </div>
  <!-- 手机视图-内容-结束 -->

  <div slot="footer">
    <el-button @click="cancel">取消</el-button>
    <el-button type="primary" @click="_previewConfirm()">确定</el-button>
  </div>
  </el-dialog>
</template>
<script>
import comTitle from '@/components/comTitle'
import phonePreviewPaper from './phonePreviewPaper.vue'
export default {
  components: { comTitle, phonePreviewPaper },
  props: {
    previewExamShow: {
      type: Boolean,
      default: false
    },
    questionsGroupList: {
      type: Array,
      default: () => []
    },
    paperId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      viewType: 'computerView'

    }
  },
  mounted () {
  },
  methods: {
    getTypeValue (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          break
      }
      return value
    },
    // 取消
    cancel () {
      this.$parent.previewExamShow = false
    },
    _previewConfirm () {
      this.$parent.previewExamShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  .type {
    color: #0089ff;
    font-size: 14px;
  }
  color: #000;
  font-size: 16px;
  .grade {
    color: #666;
    font-size: 12px;
  }
}
.explain {
  color: #999;
  font-size: 14px;
  margin: 16px 0;
}
.analysis {
  background: #f6f7f9;
  padding: 10px 12px;
  font-size: 14px;
  span {
    color: #999;
    margin-right: 8px;
    line-height: 25px;
  }
}
.handle-btn {
  font-size: 14px;
  > div {
    margin-left: 24px;
    cursor: pointer;
  }
  i {
    margin-right: 5px;
  }
}
.el-radio-group,.el-checkbox-group{
  display: flex;
  flex-direction: column;
  /deep/.el-radio,/deep/.el-checkbox{
    padding: 16px 0px 1px 0px;
  }
}
// 视图样式
.view-type-style{
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
  .computer-view{
    margin-right: 10px;
  }
}
/deep/.el-radio-button__inner{
  border-radius: 4px!important;
  border: 1px solid #DCDFE6;
  background: #DCDFE6;
  font-size: 14px!important;
}
.phone-view-content{
  text-align: center;
  .img-style{
    img{
      width:400px;
    }
  }
  .text-style{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.9);
  }
}
</style>
