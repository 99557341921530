/*
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-04 17:21:16
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-05 09:37:30
 */
export default {
  data () {
    return {
      groupDeleteArr: [],
      topicDeleteArr: []
    }
  },
  methods: {
    // 试卷--删除分组试题
    _groupDelete (arr, index) {
      const newArr = arr.splice(index, 1)
      newArr.forEach(item => {
        if (item.detailDeleteId !== undefined) {
          this.groupDeleteArr.push({
            ...item,
            delFlag: 1
          })
        }
      })
      // 删除的分组里面的题目添加删除标识
      this.groupDeleteArr.forEach((groupItem, topicIndex) => {
        groupItem.questionInfo.forEach((topicItem, topicIndex) => {
          if (topicItem.detailDeleteId !== undefined) {
            topicItem.delFlag = 1
          }
        })
      })
    },
    // 试卷管理-- 删除单个试题
    _topicDelete (arr, index) {
      const newArr = arr.splice(index, 1)
      newArr.forEach(item => {
        if (item.detailDeleteId !== undefined) {
          this.topicDeleteArr.push({
            ...item,
            delFlag: 1
          })
        }
      })
    }
  }
}
